import { ArrowRight, CheckCircle, Code, Globe, Zap } from 'lucide-react'
import { useRef } from 'react'

export default function LandingPage() {
	const contactRef = useRef(null)

	const scrollToContact = e => {
		e.preventDefault()
		contactRef.current?.scrollIntoView({ behavior: 'smooth' })
	}

	return (
		<div className="flex flex-col min-h-screen">
			<header className="w-full px-4 lg:px-6 h-14 flex items-center">
				<div className="w-full max-w-7xl mx-auto flex items-center justify-between">
					<a className="flex items-center justify-center" href="#">
						<Zap className="h-6 w-6 text-red-600" />
						<span className="ml-2 text-2xl font-bold text-gray-900">
							Fierce Trade
						</span>
					</a>
					<nav className="flex gap-4 sm:gap-6">
						<a
							className="text-sm font-medium hover:underline underline-offset-4"
							href="#contact"
							onClick={scrollToContact}
						>
							Contact
						</a>
					</nav>
				</div>
			</header>
			<main className="flex-1">
				<section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-red-50">
					<div className="max-w-7xl mx-auto px-4 md:px-6">
						<div className="flex flex-col items-center space-y-4 text-center">
							<div className="space-y-2">
								<h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
									Empowering Businesses Through Innovative Software Solutions
								</h1>
								<p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
									Your partner in digital transformation since 2019
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="w-full py-12 md:py-24 lg:py-32 bg-white">
					<div className="max-w-7xl mx-auto px-4 md:px-6">
						<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">
							Our Journey
						</h2>
						<p className="mx-auto max-w-[700px] text-gray-500 md:text-xl text-center mb-12">
							Since our inception in 2019, Fierce Trade LLC has been at the
							forefront of software innovation, consistently delivering
							cutting-edge solutions to businesses across various industries.
						</p>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
							<div className="flex flex-col items-center text-center">
								<Globe className="h-12 w-12 text-red-600 mb-4" />
								<h3 className="text-xl font-bold mb-2">Global Reach</h3>
								<p className="text-gray-500">
									Serving clients worldwide with localized solutions
								</p>
							</div>
							<div className="flex flex-col items-center text-center">
								<Code className="h-12 w-12 text-red-600 mb-4" />
								<h3 className="text-xl font-bold mb-2">
									Cutting-edge Technology
								</h3>
								<p className="text-gray-500">
									Leveraging the latest in software development
								</p>
							</div>
							<div className="flex flex-col items-center text-center">
								<CheckCircle className="h-12 w-12 text-red-600 mb-4" />
								<h3 className="text-xl font-bold mb-2">
									Customer Satisfaction
								</h3>
								<p className="text-gray-500">
									Committed to exceeding client expectations
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
					<div className="max-w-7xl mx-auto px-4 md:px-6">
						<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">
							The Fierce Trade Approach
						</h2>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
							<div className="space-y-4">
								<h3 className="text-2xl font-bold">Innovation at Our Core</h3>
								<p className="text-gray-500">
									We believe in pushing the boundaries of what's possible. Our
									team of expert developers and designers work tirelessly to
									create software solutions that not only meet current needs but
									anticipate future challenges.
								</p>
							</div>
							<div className="space-y-4">
								<h3 className="text-2xl font-bold">
									Tailored for Your Success
								</h3>
								<p className="text-gray-500">
									Every business is unique, and so are its software needs. We
									take pride in our ability to understand your specific
									requirements and deliver customized solutions that drive your
									business forward.
								</p>
							</div>
						</div>
					</div>
				</section>
			</main>
			<section
				ref={contactRef}
				id="contact"
				className="w-full py-12 md:py-24 lg:py-32 bg-white"
			>
				<div className="max-w-7xl mx-auto px-4 md:px-6">
					<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">
						Contact Us
					</h2>
					<div className="flex flex-col items-center space-y-4 text-center">
						<p className="text-gray-500 md:text-xl">
							Ready to start your next project? Get in touch with us!
						</p>
						<a
							href="mailto:info@fiercetrade.org"
							className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-red-600 hover:bg-red-700 rounded-md"
						>
							Email Us
							<ArrowRight className="ml-2 h-5 w-5" />
						</a>
					</div>
				</div>
			</section>
			<footer className="w-full border-t">
				<div className="max-w-7xl mx-auto flex flex-col gap-2 sm:flex-row py-6 items-center justify-between px-4 md:px-6">
					<p className="text-xs text-gray-500">
						© 2024 Fierce Trade LLC. All rights reserved.
					</p>
				</div>
			</footer>
		</div>
	)
}
